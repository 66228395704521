let siteName = ''
export function getSiteName () {
  if (siteName) return siteName
  const meta = document.querySelector('meta[property="og:site_name"]')
  if (meta) siteName = meta.getAttribute('content') || ''
  return siteName
}

export function setDocumentTitle (title) {
  const name = getSiteName()
  document.title = `${name} | ${title}`
}

export function generateThemeColors (expandedThemeColors) {
  const classes = []
  const vars = []

  expandedThemeColors.forEach(({ color, readable }, index) => {
    vars.push(`
      --janiis-theme-color-${index + 1}: ${color};
      --janiis-theme-color-${index + 1}-readable: ${readable};
    `)

    classes.push(`
      .janiis-theme-color-${index + 1} {
        color: var(--janiis-theme-color-${index + 1}) !important;
      }
      .janiis-theme-color-${index + 1}-bg {
        background-color: var(--janiis-theme-color-${index + 1}) !important;
        color: var(--janiis-theme-color-${index + 1}-readable) !important;
      }
    `)
  })

  vars.push(`
      --janiis-theme-color-primary: var(--janiis-theme-color-6);
      --janiis-theme-color-primary-readable: var(--janiis-theme-color-6-readable);

      --janiis-theme-color-secondary: var(--janiis-theme-color-2);
      --janiis-theme-color-secondary-readable: var(--janiis-theme-color-2-readable);
  `)

  classes.push(`
    .janiis-theme-color-primary {
      color: var(--janiis-theme-color-primary) !important;
    }
    .janiis-theme-color-secondary {
      color: var(--janiis-theme-color-secondary) !important;
    }
    .janiis-theme-color-primary-bg {
      background-color: var(--janiis-theme-color-primary) !important;
      color: var(--janiis-theme-color-primary-readable) !important;
    }
    .janiis-theme-color-secondary-bg {
      background-color: var(--janiis-theme-color-secondary) !important;
      color: var(--janiis-theme-color-secondary-readable) !important;
    }
  `)

  return `
    :root {
      ${vars.join('')}
    }

    ${classes.join('')}
  `
}
