import { h, Component } from 'preact'

import NavItem from './nav_item'
import Logo from './logo'

class Navbar extends Component {
  state = {
    isActive: false
  }

  toggleMobileNav = () => {
    this.setState({ isActive: !this.state.isActive })
  }

  closeMobileNav = () => {
    this.setState({ isActive: false })
  }

  render ({ links, name }) {
    const navbarMenuClass = `navbar-menu ${this.state.isActive ? 'is-active' : ''}`
    const navItems = links.map(link => (<NavItem key={link.label} {...link} />))

    return (
      <nav class='navbar'>
        <div class='container'>
          <div class='navbar-brand'>
            <Logo />

            <a role='button' class='navbar-burger burger' aria-label='menu' aria-expanded='false' data-target='header-nav' onclick={this.toggleMobileNav}>
              <span aria-hidden='true' />
              <span aria-hidden='true' />
              <span aria-hidden='true' />
            </a>
          </div>

          <div id='header-nav' class={navbarMenuClass}>
            <div class='navbar-end center-content' onclick={this.closeMobileNav}>
              {navItems}
            </div>
          </div>

          <div id='header-nav' className={'center-content' + ' ' + navbarMenuClass}>
            <a href='/search' class='button is-primary' style={{ height: '50px' }}>Book Direct</a>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
