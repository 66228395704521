import { h, Component } from 'preact'
import { route } from 'preact-router'

import { dateToQueryParam, didPropsChange } from '@janiis/sites-theme-support'

import Dates from './fields/dates'
import Guests from './fields/guests'
import Rates from './fields/rates'
import Select from './fields/select'
import Switch from './fields/switch'

let idSuffix = 0

class SearchFormForm extends Component {
  state = {
    checkIn: null,
    checkOut: null,
    guests: null,
    rateMin: null,
    rateMax: null,
    property: null,
    collection: null,
    featured: null,
    pets: null,
    smoking: null,
    wheelchairAccessible: null,
    allowSearch: false,
    allowReset: false,
    showAdvancedFilters: false
  }

  componentWillMount () {
    this.idSuffix = (idSuffix += 1)
  }

  componentDidMount () {
    this.setState({
      checkIn: this.props.checkIn,
      checkOut: this.props.checkOut,
      guests: this.props.guests,
      rateMin: this.props.rateMin,
      rateMax: this.props.rateMax,
      property: this.props.property,
      collection: this.props.collection,
      featured: this.props.featured,
      pets: this.props.pets,
      smoking: this.props.smoking,
      wheelchairAccessible: this.props.wheelchairAccessible
    }, () => { this.checkShouldAllowSearch() })
  }

  componentDidUpdate (prevProps) {
    const propsToMonitor = ['checkIn', 'checkOut', 'guests'] // props that might come from the footer search form
    if (didPropsChange(prevProps, this.props, propsToMonitor)) {
      this.setState({
        checkIn: this.props.checkIn,
        checkOut: this.props.checkOut,
        guests: this.props.guests
      }, () => { this.checkShouldAllowSearch() })
    }
  }

  dateChanged = (dates) => {
    const [checkIn, checkOut] = dates
    this.setState({ checkIn, checkOut }, () => { this.checkShouldAllowSearch() })
  }

  ratesChanged = ([rateMin, rateMax]) => {
    this.setState({ rateMin, rateMax }, () => { this.checkShouldAllowSearch() })
  }

  updateValue = (event) => {
    const value = event.target.value || undefined
    this.setState({ [event.target.name]: value }, () => { this.checkShouldAllowSearch() })
  }

  updateSwitch = (event) => {
    this.setState({ [event.target.name]: event.target.checked }, () => { this.checkShouldAllowSearch() })
  }

  resetState () {
    this.setState({
      checkIn: null,
      checkOut: null,
      guests: null,
      rateMin: null,
      rateMax: null,
      property: null,
      collection: null,
      featured: null,
      pets: null,
      smoking: null,
      wheelchairAccessible: null,
      allowSearch: false,
      allowReset: false
    })
  }

  onReset = (event) => {
    this.resetState()
    route('/search')
  }

  onSubmit = (event) => {
    event.preventDefault()

    const { rateMin, rateMax } = this.state

    const searchParams = new URLSearchParams()
    if (this.state.checkIn) searchParams.append('check_in', dateToQueryParam(this.state.checkIn))
    if (this.state.checkOut) searchParams.append('check_out', dateToQueryParam(this.state.checkOut))
    if (this.state.guests) searchParams.append('guests', this.state.guests)
    if (this.includeRateMin) searchParams.append('rate_min', rateMin)
    if (this.includeRateMax) searchParams.append('rate_max', rateMax)
    if (this.state.property) searchParams.append('property', this.state.property)
    if (this.state.collection) searchParams.append('collection', this.state.collection)
    if (this.state.featured) searchParams.append('featured', '1')
    if (this.state.pets) searchParams.append('pets', '1')
    if (this.state.smoking) searchParams.append('smoking', '1')
    if (this.state.wheelchairAccessible) searchParams.append('wheelchair_accessible', '1')

    this.setState({ allowSearch: false })
    if (this.props.resetOnSubmit) this.resetState()

    route(`/search?${searchParams.toString()}`)
  }

  checkShouldAllowSearch () {
    const keys = ['checkIn', 'checkOut', 'guests', 'property', 'collection', 'featured', 'pets', 'smoking', 'wheelchairAccessible']
    const ratesChanged = this.includeRateMin || this.includeRateMax

    const allowSearch = ratesChanged || didPropsChange(this.props, this.state, keys)
    this.setState({ allowSearch, allowReset: this.allowReset })
  }

  get allowReset () {
    return this.includeRateMin || this.includeRateMax || this.state.checkIn || this.state.checkOut || this.state.guests || this.state.property || this.state.collection || this.state.featured || this.state.pets || this.state.smoking || this.state.wheelchairAccessible
  }

  get includeRateMin () {
    return this.props.filters && !!this.state.rateMin && (this.state.rateMin > this.props.filters.rates[0] || (!!this.props.rateMin && this.state.rateMin !== this.props.rateMin))
  }

  get includeRateMax () {
    return this.props.filters && !!this.state.rateMax && (this.state.rateMax < this.props.filters.rates[1] || (!!this.props.rateMax && this.state.rateMax !== this.props.rateMax))
  }

  get advancedFiltersEnabled () {
    return this.props.filters && this.props.advancedFiltersEnabled !== false
  }

  get showRatesFilter () {
    return this.props.filters && this.props.filters.rates && this.props.filters.rates.length > 0
  }

  get showPropertyFilter () {
    return this.props.filters && this.props.filters.properties && this.props.filters.properties.length > 0
  }

  get showCollectionFilter () {
    return this.props.filters && this.props.filters.collections && this.props.filters.collections.length > 0
  }

  get showFeaturedFilter () {
    return this.props.filters && this.props.filters.featured === true
  }

  get showPetsFilter () {
    return this.props.filters && this.props.filters.pets === true
  }

  get showSmokingFilter () {
    return this.props.filters && this.props.filters.smoking === true
  }

  get showWheelchairAccessibleFilter () {
    return this.props.filters && this.props.filters.wheelchairAccessible === true
  }

  get filterButtonCopy () {
    return this.state.showAdvancedFilters ? 'Hide Filters' : 'Show Filters'
  }

  toggleAdvancedFilters = () => {
    const showAdvancedFilters = !this.state.showAdvancedFilters
    this.setState({ showAdvancedFilters })
  }

  get guestsMax () {
    return (this.props.filters && this.props.filters.guests) || null
  }

  getUniqueId (prefix) {
    return `${prefix}-${this.idSuffix}`
  }

  renderAdvancedFilters (filters) {
    if (!this.advancedFiltersEnabled || !this.state.showAdvancedFilters) return

    return (
      <div class='columns is-multiline is-mobile'>
        {this.showRatesFilter && <div class='column is-full'><Rates rates={filters.rates} onchange={this.ratesChanged} rateMin={this.state.rateMin} rateMax={this.state.rateMax} /></div>}
        {this.showPropertyFilter && <div class='column is-full-mobile is-half-tablet'><Select id={this.getUniqueId('property')} name='property' label='Property' data={filters.properties} onchange={this.updateValue} selected={this.state.property} /></div>}
        {this.showCollectionFilter && <div class='column is-full-mobile is-half-tablet'><Select id={this.getUniqueId('collection')} name='collection' label='Collection' data={filters.collections} onchange={this.updateValue} selected={this.state.collection} /></div>}
        <div class='column is-full'>
          <div class='columns'>
            {this.showWheelchairAccessibleFilter && <div class='column is-full-mobile is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-fifth-fullhd'><Switch id={this.getUniqueId('wheelchairAccessible')} name='wheelchairAccessible' label='Accessible' onchange={this.updateSwitch} checked={this.state.wheelchairAccessible} /></div>}
            {this.showFeaturedFilter && <div class='column is-full-mobile is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-fifth-fullhd'><Switch id={this.getUniqueId('featured')} name='featured' label='Featured' onchange={this.updateSwitch} checked={this.state.featured} /></div>}
            {this.showPetsFilter && <div class='column is-full-mobile is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-fifth-fullhd'><Switch id={this.getUniqueId('pets')} name='pets' label='Pets' onchange={this.updateSwitch} checked={this.state.pets} /></div>}
            {this.showSmokingFilter && <div class='column is-full-mobile is-one-quarter-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-fifth-fullhd'><Switch id={this.getUniqueId('smoking')} name='smoking' label='Smoking' onchange={this.updateSwitch} checked={this.state.smoking} /></div>}
          </div>
        </div>
      </div>
    )
  }

  renderFullForm (filters) {
    return (
      <form onsubmit={this.onSubmit} onreset={this.onReset}>
        <div class='columns is-mobile'>
          <div class='column'>
            <Dates id={this.getUniqueId('dates')} name='dates' checkInDate={this.state.checkIn} checkOutDate={this.state.checkOut} onChange={this.dateChanged} showLabel />
          </div>
          <div class='column is-one-third'>
            <Guests id={this.getUniqueId('guests')} name='guests' max={this.guestsMax} oninput={this.updateValue} value={this.state.guests} showLabel />
          </div>
        </div>
        {this.renderAdvancedFilters(filters)}

        <div class='columns is-mobile is-multiline justify-content-between align-items-center'>
          <div class='column is-narrow'>
            <div class='buttons'>
              <button class='button is-primary' type='submit' disabled={!this.state.allowSearch}>Search</button>
              <button class='button is-light is-small-mobile' type='reset' disabled={!this.state.allowReset}>Reset</button>
            </div>
          </div>
          <div class='column is-narrow'>
            {this.advancedFiltersEnabled && <button type='button' onclick={this.toggleAdvancedFilters} class='button is-text is-small'>{this.filterButtonCopy}</button>}
          </div>
        </div>
      </form>
    )
  }

  renderOneLineForm (filters) {
    return (
      <form onsubmit={this.onSubmit} onreset={this.onReset}>
        <div class='columns' style='justify-content: center'>
          <div class='column is-two-fifths has-margin-none-bottom'>
            <Dates id={this.getUniqueId('dates')} name='dates' checkInDate={this.state.checkIn} checkOutDate={this.state.checkOut} onChange={this.dateChanged} placeholder='Dates' />
          </div>
          <div class='column has-margin-none-bottom is-one-fifth-tablet is-one-fifth-desktop is-one-fifth-widescreen is-one-fifth-full-hd'>
            <Guests id={this.getUniqueId('guests')} name='guests' max={this.guestsMax} oninput={this.updateValue} value={this.state.guests} placeholder='Guests' />
          </div>
          <div class='column is-narrow'>
            <button class='button is-primary has-border has-border-white' style='width: 7.5em' type='submit'>Search</button>
          </div>
        </div>
      </form>
    )
  }

  render ({ oneLine, filters }) {
    if (oneLine) {
      return this.renderOneLineForm(filters)
    } else {
      return this.renderFullForm(filters)
    }
  }
}

export default SearchFormForm
